@media screen and (max-width: 850px) {
  .side-section {
    display: none;
  }
  .responsive-space {
    display: block;
  }
  .responsive-logo {
    display: inline-block;
  }
}

@media screen and (min-width: 851px){
  .responsive-space {
    display: none;
  }
  .responsive-logo {
    display: none;
  }
}